<template>
  <div class="">
    <h4
      v-if="title"
      class="h4"
    >
      {{ title }}
    </h4>
    <MarkdownBlock
      v-if="text"
      :text="text"
      :inline="false"
      tag="div"
    />
  </div>
</template>

<script>
import { MarkdownBlock } from '@monogrid/vue-lib'

export default {
  name: 'ModularFeature',
  components: { MarkdownBlock },
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>
